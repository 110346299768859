import { getPinoOptions } from "@relaycorp/pino-cloud";
import pino from "pino";

export const logger = pino({
  ...getPinoOptions("gcp", {
    name: 'wellcheck',
    version: '1.0.2',
  }),
});

export default logger;
